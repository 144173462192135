import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

class App extends Component {
  render() {
    return (
      <div>
      <div className='frame'>
      <div className='focal'>
      <div className='title-image title-image-1'>
      </div>
      <div className='title-image title-image-2'>
      </div>
      <div className='title title-1'>
      <span>GUERILLA<br/>ART<br/>YOUTH<br/>SCHOLARS</span>
      </div>
      </div>
      </div>
      <div className='frame frame-dark'>
      <div className='title-link'>
      <a href="mailto:mail@artyouth.org">contact us</a>
      </div>
      </div>
      </div>
    );
  }
}

export default App;
